/* eslint-disable consistent-return */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// React
import { useEffect, useState } from 'react';

// Components
import LoadingOverlay from '../../shared/components/organisms/LoadingOverlay';

// Configs
import brand from '../../configs/brands.configs';

// Others
import useStore from '../../shared/store';

/* -------------------------------------------------------------------------- */
/*                                  Provider                                  */
/* -------------------------------------------------------------------------- */

const BrandProvider = (props) => {
  /* ******************************** LOGIC ******************************* */

  const [isLoading, setIsLoading] = useState(true);

  const { children } = props;

  const { loadBrand } = useStore();

  useEffect(() => {
    loadBrand(brand);

    setIsLoading(false);
  }, []);

  /* ******************************** RENDERING ******************************* */

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return children;
};

export default BrandProvider;
