/* eslint-disable consistent-return */
// Lib dependencies
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

// Others
import useStore from '../shared/store';

import appRoutes from '../router/configs/routes.configs';

/* -------------------------------------------------------------------------- */
/*                                    Hook                                    */
/* -------------------------------------------------------------------------- */

const useRouterNavigation = () => {
  const { brand, entity } = useStore();

  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Constructs a path based on the target path and query parameters.
   *
   * @param {string} targetPath - The target path for the URL.
   * @param {Object} queryParams - The query parameters to be included in the path.
   * @returns {string} - The constructed path.
   */
  const makePath = (targetPath, queryParams) => {
    let modifiedQueryParams = queryParams || {};

    if (entity.isLoaded) {
      modifiedQueryParams = {
        ...modifiedQueryParams,
        UID: entity.data.reference,
      };
    }

    const stringifiedQueryParams = `?${queryString.stringify(modifiedQueryParams)}`;

    const fullPath = `${targetPath}${stringifiedQueryParams}`;

    return fullPath;
  };

  /**
   * Navigates to the specified path.
   *
   * @param {string} targetPath - The path to navigate to.
   */
  const navigateTo = (targetPath, queryParams) => {
    if (targetPath === '/404' && !brand) {
      return navigate(targetPath);
    }

    navigate(makePath(targetPath, queryParams));
  };

  /**
   * Navigates back to the previous page.
   */
  const navigateBack = () => {
    navigate(-1);
  };

  /**
   * Opens the specified URL in a new tab.
   *
   * @param {string} targetPath - The URL to open in a new tab.
   */
  const openNewTab = (targetPath) => {
    window.open(targetPath, '_blank');
  };

  const getCurrentPath = () => {
    const paths = Object.values(appRoutes).map((appRoute) => appRoute.path);

    // Sort to match logest paths first
    paths.sort((a, b) => {
      return b.length - a.length;
    });

    const matchingKey = paths.find((key) => location.pathname.includes(key));

    return matchingKey || null;
  };

  return { navigateTo, navigateBack, openNewTab, getCurrentPath };
};

export default useRouterNavigation;
