// Lib dependencies
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import React from 'react';

// Providers
import BrandProvider from './providers/BrandProvider';
import I18nProvider from './i18n/providers/I18nProvider';
import EntityProvider from './providers/EntityProvider';
import MantineProvider from './mantine/providers/MantineProvider';
import ModalsProvider from './mantine/providers/ModalsProvider';
import GlobalStylesProvider from './mantine/providers/GlobalStylesProvider';

// Layout
import DynamicAppMetadata from './shared/layout/DynamicAppMetadata';

// Router
import Router from './router';
import appRoutes from './router/configs/routes.configs';

// Config
import environment from './configs/environment.configs';

const App = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });

    ReactGA.initialize(environment.ga.measurementId);
    ReactGA.send({ hitType: 'pageview', page: pathname, title: 'Pageview' });
  }, []);
  return (
    <MantineProvider>
      <ModalsProvider>
        <GlobalStylesProvider />
        {pathname !== appRoutes.globalNotFound.path ? (
          <BrandProvider>
            <DynamicAppMetadata>
              <I18nProvider>
                {pathname.includes(appRoutes.notFound.path) ? (
                  <Router />
                ) : (
                  <EntityProvider>
                    <Router />
                  </EntityProvider>
                )}
              </I18nProvider>
            </DynamicAppMetadata>
          </BrandProvider>
        ) : (
          <Router />
        )}
      </ModalsProvider>
    </MantineProvider>
  );
};

export default App;
