// Lib dependencies
import { Routes, Route, Navigate } from 'react-router-dom';

// Configs
import appRoutes from './configs/routes.configs';

// Others
import useStore from '../shared/store';

/* -------------------------------------------------------------------------- */
/*                                  Router                                  */
/* -------------------------------------------------------------------------- */

const Router = () => {
  const { entity } = useStore();

  /* ******************************** RENDERING ******************************* */

  return (
    <Routes>
      {Object.entries(appRoutes).map(([key, route]) => {
        const { path } = route;

        // Skip entity routes if entity is not loaded
        if (!entity.isLoaded && (path === appRoutes.category.path || path === appRoutes.product.path)) {
          return null;
        }

        // Skip product route if entity is loaded and has 'category' type
        // Skip category route if entity is loaded and has 'product' type
        if (
          entity.isLoaded &&
          ((entity.type === 'category' && path === appRoutes.product.path) ||
            (entity.type === 'product' && path === appRoutes.category.path))
        ) {
          return null;
        }

        // Skip certification routes if entity is already certified
        if (entity.isLoaded && entity.data.certified && path === appRoutes.certification.path) {
          return null;
        }

        return <Route key={key} path={route.route} element={route.element} />;
      })}
      {
        // Global 404 page
        <Route path="*" element={<Navigate to={appRoutes.globalNotFound.path} />} />
      }
    </Routes>
  );
};

export default Router;
